.customer-badge,
.product-badge,
.order-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.product-badge {
    &.status-instock {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-outofstock {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-lowstock {
        background: #FEEDAF;
        color: #8A5340;
    }
}

.customer-badge {
    &.status-qualified {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-unqualified {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-negotiation {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-new {
        background: #B3E5FC;
        color: #23547B;
    }

    &.status-renewal {
        background: #ECCFFF;
        color: #694382;
    }

    &.status-proposal {
        background: #FFD8B2;
        color: #805B36;
    }
}

.order-badge {
    &.order-delivered {
        background: #C8E6C9;
        color: #256029;
    }

    &.order-cancelled {
        background: #FFCDD2;
        color: #C63737;
    }

    &.order-pending {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.order-returned {
        background: #ECCFFF;
        color: #694382;
    }
}

.block-viewer {
    .block-section {
        margin-bottom: 4rem;
        overflow: hidden;
    }

    .block-header {
        padding: 1rem 2rem;
        background-color: var(--surface-section);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border: 1px solid var(--surface-d);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .block-title {
            font-weight: 700;
            display: inline-flex;
            align-items: center;

            .badge-free {
                border-radius: 4px;
                padding: 0.25rem 0.5rem;
                background-color: var(--orange-500);
                color: white;
                margin-left: 1rem;
                font-weight: 700;
                font-size: 0.875rem;
            }
        }

        .block-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            margin-left: 1rem;

            a,
            button {
                display: flex;
                align-items: center;
                margin-right: 0.75rem;
                padding: 0.5rem 1rem;
                border-radius: 4px;
                font-weight: 600;
                border: 1px solid transparent;
                transition: background-color 0.2s;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &:not(.block-action-disabled):hover {
                    background-color: var(--surface-c);
                }

                &.block-action-active {
                    border-color: var(--primary-color);
                    color: var(--primary-color);
                }

                &.block-action-copy {
                    i {
                        color: var(--primary-color);
                        font-size: 1.25rem;
                        margin: 0;
                    }
                }

                &.block-action-disabled {
                    opacity: 0.6;
                    cursor: auto !important;
                }

                i {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .block-content {
        padding: 0;
        border: 1px solid var(--surface-d);
        border-top: 0 none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        overflow: hidden;

        pre.app-code {
            background-color: var(--surface-card) !important;
            padding-bottom: 1rem !important;
            margin-bottom: 0 !important;

            code {
                padding: 1rem 2.5rem;
            }
        }
    }

    @media screen and (max-width: 575px) {
        .block-header {
            flex-direction: column;
            align-items: start;

            .block-actions {
                margin-top: 1rem;
                margin-left: 0;
            }
        }
    }
}

pre.app-code {
    background-color: var(--surface-ground);
    margin: 0 0 1rem 0;
    padding: 0;
    border-radius: var(--border-radius);
    overflow: auto;

    code {
        color: var(--surface-900);
        padding: 1rem;
        line-height: 1.5;
        display: block;
        font-family: monaco, Consolas, monospace;
    }
}

.timeline-demo .custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.timeline-demo .p-timeline-event-content,
.timeline-demo .p-timeline-event-opposite {
    line-height: 1;
}

@media screen and (max-width: 960px) {
    .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) {
        flex-direction: row !important;
    }

    .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
        text-align: left !important;
    }

    .timeline-demo .customized-timeline .p-timeline-event-opposite {
        flex: 0;
    }

    .timeline-demo .customized-timeline .p-card {
        margin-top: 1rem;
    }
}

.layout-config-button {
    display: block;
    position: fixed;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    background: var(--primary-color);
    color: var(--primary-color-text);
    text-align: center;
    top: 50%;
    right: 0;
    margin-top: -1.5rem;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: background-color var(--transition-duration);
    overflow: hidden;
    cursor: pointer;
    z-index: 999;
    box-shadow: -.25rem 0 1rem rgba(0, 0, 0, .15);


    &.config-link {
        font-size: 1rem;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        border: none;
    }

    .config-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 1px var(--focus-ring);
    }

    i {
        font-size: 2rem;
        line-height: inherit;
        transform: rotate(0deg);
        transition: transform 1s;
    }

    &:hover {
        background: var(--primary-400);
    }
}


.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 4rem;
    transition: margin-left;
}

.layout-main {
    flex: 1 1 auto;
}

.layout-footer {
    transition: margin-left;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    border-top: 1px solid var(--surface-border);
}

#bottom {
    bottom: 0;
    text-align: bottom;
}

* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

header {
    color: black;
    background-color: white;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: white;
    background-color: rgb(255, 255, 255);
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

.layout-wrapper {
    min-height: 100vh;
}

.layout-sidebar {
    position: fixed;
    width: 300px;
    height: calc(100vh - 9rem);
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    top: 7rem;
    left: 2rem;


    padding: 0.5rem 1.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);
}

.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    user-select: none;

    .layout-root-menuitem {
        >.layout-menuitem-root-text {
            font-size: .857rem;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--surface-900);
            margin: .75rem 0;
        }

        >a {
            display: none;
        }
    }

    li.active-menuitem {
        >a {
            .layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }

        >ul {
            max-height: auto;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
            display: flex;
            align-items: center;
            position: relative;
            outline: 0 none;
            color: var(--text-color);
            cursor: pointer;
            padding: .75rem 1rem;



            .layout-menuitem-icon {
                margin-right: .5rem;
            }

            .layout-submenu-toggler {
                font-size: 75%;
                margin-left: auto;

            }

            &.rotated-icon {
                .layout-menuitem-icon {
                    transform: rotate(90deg);
                }
            }

            &.active-route {
                font-weight: 700;
                color: var(--primary-color);
            }

            &:hover {
                background-color: var(--surface-hover);
            }



            ul {
                overflow: hidden;
                max-height: 0;


                li {
                    a {
                        margin-left: 1rem;
                    }

                    li {
                        a {
                            margin-left: 2rem;
                        }

                        li {
                            a {
                                margin-left: 2.5rem;
                            }

                            li {
                                a {
                                    margin-left: 3rem;
                                }

                                li {
                                    a {
                                        margin-left: 3.5rem;
                                    }

                                    li {
                                        a {
                                            margin-left: 4rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .layout-submenu-enter-active {
        max-height: 1000px !important;
        transition: max-height 1s ease-in-out;
    }

    .layout-submenu-enter-done {
        max-height: 1000px !important;
    }

    .layout-submenu-exit {
        max-height: 1000px !important;
    }

    .layout-submenu-exit-active {
        overflow: hidden;
        max-height: 0 !important;

    }
}


@media screen and (min-width: 1960px) {

    .layout-main,
    .landing-wrapper {
        width: 1504px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media (min-width: 992px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: 0;
                padding-left: 2rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.layout-overlay-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }

        &.layout-static {
            .layout-main-container {
                margin-left: 300px;
            }

            &.layout-static-inactive {
                .layout-sidebar {
                    transform: translateX(-100%);
                    left: 0;
                }

                .layout-main-container {
                    margin-left: 0;
                    padding-left: 2rem;
                }
            }
        }

        .layout-mask {
            display: none;
        }
    }
}

@media (max-width: 991px) {
    .blocked-scroll {
        overflow: hidden;
    }

    .layout-wrapper {
        .layout-main-container {
            margin-left: 0;
            padding-left: 2rem;
        }

        .layout-sidebar {
            transform: translateX(-100%);
            left: 0;
            top: 0;
            height: 100vh;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .layout-mask {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 998;
            width: 100%;
            height: 100%;
            background-color: var(--maskbg);
        }

        &.layout-mobile-active {
            .layout-sidebar {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;

            }
        }
    }
}

.layout-topbar {
    position: fixed;
    height: 5rem;
    z-index: 997;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 2rem;
    background-color: var(--surface-card);

    display: flex;
    align-items: center;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);

    .layout-topbar-logo {
        display: flex;
        align-items: center;
        color: var(--surface-900);
        font-size: 1.5rem;
        font-weight: 500;
        width: 300px;
        border-radius: 12px;

        img {
            height: 2.5rem;
            margin-right: .5rem;
        }


    }

    .layout-topbar-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--text-color-secondary);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        cursor: pointer;


        &:hover {
            color: var(--text-color);
            background-color: var(--surface-hover);
        }


        i {
            font-size: 1.5rem;
        }

        span {
            font-size: 1rem;
            display: none;
        }
    }

    .layout-menu-button {
        margin-left: 2rem;
    }

    .layout-topbar-menu-button {
        display: none;

        i {
            font-size: 1.25rem;
        }
    }

    .layout-topbar-menu {
        margin: 0 0 0 auto;
        padding: 0;
        list-style: none;
        display: flex;

        .layout-topbar-button {
            margin-left: 1rem;
        }
    }
}

@media (max-width: 991px) {
    .layout-topbar {
        justify-content: space-between;

        .layout-topbar-logo {
            width: auto;
            order: 2;
        }

        .layout-menu-button {
            margin-left: 0;
            order: 1;
        }

        .layout-topbar-menu-button {
            display: inline-flex;
            margin-left: 0;
            order: 3;
        }

        .layout-topbar-menu {
            margin-left: 0;
            position: absolute;
            flex-direction: column;
            background-color: var(--surface-overlay);
            box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);
            border-radius: 12px;
            padding: 1rem;
            right: 2rem;
            top: 5rem;
            min-width: 15rem;
            display: none;
            -webkit-animation: scalein 0.15s linear;
            animation: scalein 0.15s linear;

            &.layout-topbar-menu-mobile-active {
                display: block
            }

            .layout-topbar-button {
                margin-left: 0;
                display: flex;
                width: 100%;
                height: auto;
                justify-content: flex-start;
                border-radius: 12px;
                padding: 1rem;

                i {
                    font-size: 1rem;
                    margin-right: .5rem;
                }

                span {
                    font-weight: medium;
                    display: block;
                }
            }
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: var(--surface-900);

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

mark {
    background: #FFF8E1;
    padding: .25rem .4rem;

    font-family: monospace;
}

blockquote {
    margin: 1rem 0;
    padding: 0 2rem;
    border-left: 4px solid #90A4AE;
}

hr {
    border-top: solid var(--surface-border);
    border-width: 1px 0 0 0;
    margin: 1rem 0;
}

p {
    margin: 0 0 1rem 0;
    line-height: 1.5;

    &:last-child {
        margin-bottom: 0;
    }
}

.header {
    color: black;
    display: flex;
    justify-content: space-between;
    padding: 10px; /* Adjust padding as needed */
    background-color: #ffffff; /* Header background color */
}

  .hamburger-menu {
    align-items: right;
    justify-content: right;
    display: flex;
    font-size: 1rem;
    line-height: 2;
    margin-right: 8px;
  }
  
  @media screen and (min-width: 990px) {
    /* Hide the original "Contact Us" link on smaller screens */
    .contact {
        display: block;
    }

    /* Display the hamburger menu on smaller screens */
    .hamburger-menu {
        display: none;
    }
  }

    /* Media query for smaller screens */
    @media screen and (max-width: 990px) {
    /* Hide the original "Contact Us" link on smaller screens */
    .contact {
        display: none;
    }

    /* Display the hamburger menu on smaller screens */
    .hamburger-menu {
        display: block;
    }

    

  .contact {
    align-items: right;
    justify-content: right;
    padding: 10px;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 3;
    margin-right: 8px;
  }
  
  .ui-dropdown .ui-dropdown-trigger .ui-dropdown-icon {
    display: none;
  }

}

.p-sidebar-top {

    background-color: #000000
}

.text-gray-700{
    color: white !important;
}
.section1 {
    background-color: #9E005D;
    padding: 100px;
    
}

.section2 {
    background-color: rgb(0, 0, 0);
    padding: 100px;
}